import React from "react"
import Layout from "../components/Layout"
import big from "../assets/images/bgmain.jpg"
import styled from "styled-components"
import "bootstrap/dist/css/bootstrap.min.css"
import { Container, Row, Col } from "react-bootstrap"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

//para espacio de spacer hacer pequeno el background
const spacer = 20

//para agrandamiento de segundo porcentaje de background
const percentage = 200

const Franja = styled.div`
  background: black;
  height: 60px;
`

const TituloBox = styled.div`
  font-size: 38px;
  font-weight: 700;
  font-family: "Titillium Web";
  letter-spacing: -2px;
  color: white;
`
const MensajeBox = styled.div`
  font-size: 58px;
  font-weight: 700;
  font-family: "Titillium Web";
  letter-spacing: -2px;
  text-align: center;
  padding-top: 50px;
  padding-bottom: 50px;
  @media (max-width: 768px) {
    font-size: 30px;
  }
`

const LeftBox = styled.div`
  font-size: 25px;
  font-weight: 500;
  font-family: "Titillium Web";
  letter-spacing: -2px;
  /* text-align: center; */
  padding-top: 50px;
  padding-bottom: 50px;
`

const RightBox = styled.div`
  font-size: 25px;
  font-weight: 500;
  font-family: "Titillium Web";
  /* letter-spacing: -2px; */
  /* text-align: center; */
  padding-top: 50px;
  padding-bottom: 50px;
`
const Boldie = styled.div`
  font-weight: bold;
`

const Marketing = () => {
  return (
    <Layout sbiggo={big} spacer={spacer} percentage={percentage}>
      <Franja>
        <Container>
          <Row>
            <Col>
              <TituloBox>Marketing Digital</TituloBox>
            </Col>
          </Row>
        </Container>
      </Franja>
      <Container>
        <Row>
          <Col>
            <MensajeBox>
              Creamos experiencias UX de alta calidad para su negocio.
              Incrementamos sus ventas en el mercado digital
            </MensajeBox>
          </Col>
        </Row>
      </Container>
      <StaticImage
        src="../assets/images/marketing.jpg"
        alt="web"
        aspectRatio={26 / 9}
      />
      <Container>
        <Row>
          <Col md={3} className="d-none d-lg-block">
            <LeftBox>
              <Boldie>Contáctenos: </Boldie>
              <br /> info@yaku.com.ec <br />
              +593999944405
            </LeftBox>
          </Col>
          <Col md={9} xs={12} className="d-lg-block">
            <RightBox>
              En Yaku proporcionamos asesoría y puesta en marcha de proyectos de
              marketing digital. Mediante los principios de UX unificamos todos
              los componentes de su plataforma digital es una especie de
              orquestación que le permite tener control de sus redes sociales en
              concordancia con su sitio web, blogs, mailing y otros servicios
              digitales que tenga publicados. <br /> <br />
              <br />
              Nos basamos en los siguientes principios UX: <br /> <br />
              1. Creación de Valor <br /> <br />
              2. Accesibilidad <br /> <br />
              3. Verificación <br /> <br />
              4. Deseable <br /> <br />
              5. Creible <br /> <br />
              6. Usable
              <br /> <br /> <br /> Estos principios los aplicamos en sus
              plataformas digitales de tal manera que su Gerencia de Marketing
              tenga un tablero digital de control, seguimiento y pueda
              implementar campañas de marketing digital en un escenario
              interconectado.
              <br /> <br /> Nuestros servicios también ofrecen asesoria técnica
              que realiza recomendaciones sobre sus productos digitales, cambios
              sobre su estrategia de marketing digital, otros.
              <br /> <br />
              <br />
              <Boldie>Experiencia</Boldie>
              <br /> <br />
              Hemos creado soluciones para empresas líderes del sector privado.
              También trabajamos con proyectos del sector público. Con mucho
              gusto le podemos hacer llegar nuestro portafolio de proyectos
              creados. Contáctenos pronto!
              <br /> <br />
              Mantenemos una amplia cartera de clientes que han confiado en
              nuestro trabajo. Hemos creado sitios web, aplicaciones web,
              aplicaciones sociales, entre otros. <br />
              <br />A través de los años hemos incrementado el uso de nuevas
              tecnologías que le permitan a nuestros clientes poder solventar
              cualquier requerimiento sin importar el grado de complejidad.
              <br />
              <br />
              ¿Tiene un nuevo proyecto? Permítanos ayudarle a conseguir los
              objetivos planificados. Nuestro soporte es personalizado, somos
              parte de su equipo y nos comprometemos a obtener las metas de cada
              proyecto. <br />
              <br />
              <br />
            </RightBox>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default Marketing
